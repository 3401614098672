import React, { useState } from "react";
import "./style.css";
import { getMaterialOffline } from "../../../../../api/lesson";
import DownloadBackground from "../../../../../assets/images/bg-offline.png";
import DownloadIcon  from "../../../../../assets/images/Download-icon.svg";
import LessonTitle from "../lesson-title";
import { useTranslation } from "react-i18next";


const DownloadLesson = ({
  lesson,
  kids,
  subject,
  lessonId,
  setLoading,
  loading,
}) => {
  const { t } = useTranslation(["detail-lesson"]);
  const [materials, setMaterials] = useState(null);

  const getMaterialOfflineByLesson = async (format) => {
    setLoading(true);
    try {
      const res = await getMaterialOffline(lessonId, -1, format);
      /** Modified by: Pedro Ortiz on 01/20/2021
       * The endpoint now just return the URL of the PDF (before was res.data.Location)
       * **/
      const data = res.data;
      if (data) {
        window.open(data, "_blank");
      }
      setMaterials(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  let className = "wrapper-iframe";
  if (kids) {
    className = "wrapper-iframe-kids";
  }
  /** Modified by: Pedro Ortiz on 01/20/2021
   * before materials was an array, now is a simple string
   * **/
  return (
    <>
      <div className="wrapper-content">
        <div className="center-container">
          <div className={className}>
            {lesson && <LessonTitle subject={subject} lesson={lesson} />}
            <div className="wrapper-list-w-icon">
              <div className="list-w-icon">
                <div className="list-w-icon__right">
                  <img
                    src={DownloadIcon}
                  />
                </div>
                <div className="list-w-icon__left">
                  <p className="list-w-icon__txt-ppal">
                    {t("titleDownloadLesson")}
                  </p>
                  <p>
                    {t("subTitleDownloadLesson")}
                    <div className="quiz-lesson__download--btn">
                      <div style={{cursor: 'pointer'}} onClick={() => getMaterialOfflineByLesson("pdf")}><i className="quiz-lesson__icon-file-download fas fa-file-pdf quiz-lesson__icon-file-download__pdf"></i><span className="quiz-lesson__download-txt quiz-lesson__icon-file-download__pdf">PDF</span></div>
                      <div style={{cursor: 'pointer'}} onClick={() => getMaterialOfflineByLesson("doc")}><i className="quiz-lesson__icon-file-download fas fa-file-word quiz-lesson__icon-file-download__word"></i><span className="quiz-lesson__download-txt quiz-lesson__icon-file-download__word">Word</span></div>
                    </div>
                  </p>
                </div>
              </div>

              <div className="bg-list-offline">
                {" "}
                <img
                  src={DownloadBackground}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadLesson;
