export const environment = {
  apikey: "48e48060-c718-46d1-92a6-d458c5e4a52a",
};

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;


export const URL_FORM_CLICKUP_EN = "https://genialskills.reqsupport.com/support/home";
export const URL_FORM_CLICKUP_SP = "https://genialskills.reqsupport.com/support/home";

export const BASE_URL_SITE_TEACHER = process.env.REACT_APP_BASE_URL_SITE_TEACHER;

export const GENIAL_WEB_LOGIN_PAGE = "./#/login";
export const KINDER_INTERACTIVE_RESOURCE = "https://wonderful-pebble-09136350f.5.azurestaticapps.net/";
export const GS_WEB_APP_INTERACTIVE = "https://d1aaqrbmwl1rtk.cloudfront.net/";
export const HTTP_USER_MANUAL_STUDENTS =
  "https://privateschools-manuals.genialskillsweb.com/GenialsSkils-Estudiante.pdf";

export const ROLE_DIRECTOR_STR = "director";
export const ROLE_TEACHER_STR = "teacher";
export const ROLE_STUDENT_STR = "student";

export const IS_APP_MANAGED_BY_REQUIRED = false;
export const IS_APP_MAINTENANCE = false;

export const SUBSCRIPTION_API_URL = process.env.REACT_APP_SUBSCRIPTION_API_URL;
export const USERS_API_URL = process.env.REACT_APP_USERS_API_URL;
export const GS_WEB_API_URL = process.env.REACT_APP_GS_WEB_API_URL;
export const MESSAGES_API_URL = process.env.REACT_APP_MESSAGES_API_URL;
export const TEACHERS_API_URL = process.env.REACT_APP_TEACHERS_API_URL;
export const ATHENAS_API_URL = process.env.REACT_APP_ATHENAS_API_URL;
export const DATAWAREHOUSE_API_URL = process.env.REACT_APP_DATAWAREHOUSE_API_URL;

export const ENVIROMENT = process.env.REACT_APP_ENV
export const PORT_API_LOGIN = USERS_API_URL;
export const PORT_API_PASSWORD_RESET_EMAIL = USERS_API_URL;
export const PORT_API_PASSWORD_RESET_MODIFY = USERS_API_URL;
export const PORT_API_COURSES = SUBSCRIPTION_API_URL;
export const PORT_API_LESSON_DETAIL = GS_WEB_API_URL;
export const PORT_API_SUBMIT_QUIZ = GS_WEB_API_URL;
export const PORT_API_LESSONS = ATHENAS_API_URL;
export const PORT_API_GET_QUIZ = ATHENAS_API_URL;
export const PORT_API_GET_QUIZ_AVAILABLE = TEACHERS_API_URL;
export const PORT_API_GET_LIBRARY = ATHENAS_API_URL;
export const PORT_API_ADDITIONAL_MATERIAL = ATHENAS_API_URL;
export const PORT_API_GET_ADDITIONAL_MATERIAL_BY_ID = ATHENAS_API_URL;
export const PORT_API_OFFLINE_MATERIAL = ATHENAS_API_URL;
export const PORT_API_GET_LESSON_ASSIGNED_TEACHER = TEACHERS_API_URL;
export const PORT_API_GET_HOMEWORK_ASSIGNED_TEACHER = TEACHERS_API_URL;
export const PORT_API_HOMEWORK_AUTHORIZATION_CODE = TEACHERS_API_URL;
export const PORT_API_GET_HOMEWORK_QUIZ = TEACHERS_API_URL;
export const PORT_API_HOMEWORK_QUIZ_CORRECTION = TEACHERS_API_URL;
export const PORT_API_GET_FILES_RULES_HOMEWORK = TEACHERS_API_URL;
export const PORT_API_FILE_UPLOAD_QUIZ = TEACHERS_API_URL;
export const PORT_API_COMPLETED_ASSIGNMENTS = TEACHERS_API_URL;
export const PORT_API_DETAIL_COMPLETED_ASSIGNMENT = TEACHERS_API_URL;
export const PORT_API_GET_MESSAGE_UNREAD = MESSAGES_API_URL;
export const PORT_API_GET_MESSAGE_RECEIVED = MESSAGES_API_URL;
export const PORT_API_GET_MESSAGE_SENT = MESSAGES_API_URL;
export const PORT_API_GET_DETAIL_MESSAGE = MESSAGES_API_URL;
export const PORT_API_GET_FILES_RULES = MESSAGES_API_URL;
export const PORT_API_MESSAGE_SEND = MESSAGES_API_URL;
export const PORT_API_MESSAGE_MARK_READ = MESSAGES_API_URL;
export const PORT_API_FILE_UPLOAD = MESSAGES_API_URL;
export const PORT_API_CONNECTED_USERS = MESSAGES_API_URL;
export const PORT_API_GET_FORUM_BY_LEVEL_SUBJECT = TEACHERS_API_URL;
export const PORT_API_GET_FORUM_DETAIL = TEACHERS_API_URL;
export const PORT_API_FORUM_CREATE_POST = TEACHERS_API_URL;
export const PORT_API_FORUM_UPDATE_POST = TEACHERS_API_URL;
export const PORT_API_FORUM_DELETE_POST = TEACHERS_API_URL;
export const PORT_API_ACTIVITY_CREATE = USERS_API_URL;
export const PORT_API_CHAT_CONTACTS = USERS_API_URL;

export const PORT_API_GET_TRIES_SCHOOOL = TEACHERS_API_URL;
export const PORT_API_GET_TRIES_HOMESCHOOLER = GS_WEB_API_URL;
export const PORT_API_GET_TRIES_EXAMS_SCHOOOL = TEACHERS_API_URL;

export const PORT_API_GET_SUBSCRIPTIONS_ACTIVE_PERIODS = SUBSCRIPTION_API_URL;
export const PORT_API_GET_REPORTS_AVERAGE_PERIOD = TEACHERS_API_URL;
export const PORT_API_GET_ACADEMICS_YEARS = SUBSCRIPTION_API_URL;
export const PORT_API_GET_ACADEMICS_DETAILS_BY_YEAR = DATAWAREHOUSE_API_URL;
export const PORT_API_GET_REPORT_ACADEMICS_DETAILS_BY_YEAR = DATAWAREHOUSE_API_URL;
