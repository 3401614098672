import React from "react";
import "./style.css";
import LessonMarkIcon from "../../../../../assets/images/lesson-mark.svg";
import LessonMenuConcept from "../../../../../assets/images/LessonMenu-icon-concept.svg";
import LessonMenuVocabulary from "../../../../../assets/images/LessonMenu-icon-vocabulary.svg";
import LessonMenuExamples from "../../../../../assets/images/LessonMenu-icon-examples.svg";
import LessonMenuPractice from "../../../../../assets/images/LessonMenu-icon-practice.svg";
import LessonMenuChallege from "../../../../../assets/images/LessonMenu-icon-challenge.svg";
import LessonMenuMaterial from "../../../../../assets/images/Lesson-aditional-icon.svg";
import LessonMenuDownload from "../../../../../assets/images/Lesson-download-icon.svg";
import LessonMenuFinish from "../../../../../assets/images/LessonMenu-icon-finish.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import prevPath from "../../../../../redux/actions/path";
import { hasRole, getUser } from "../../../../../utils/utils";
import { ROLE_DIRECTOR_STR } from "../../../../../config/environment";

const LessonMenu = ({ tabActive, setTabActive }) => {
  const { t, i18n } = useTranslation(["detail-lesson"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const userCurrent = getUser();

  const path = useSelector((state) => state.prevPath);

  const goBack = () => {
    if (hasRole(userCurrent, [ROLE_DIRECTOR_STR])) {
      history.push("/students/lesson");
    } else {
      if (path && path.indexOf("/students/lesson/quiz/") !== -1) {
        history.push("/students/home");
        dispatch(prevPath(null));
      } else {
        history.goBack();
      }
    }
  };

  return (
    <>
      <div className="wrapper-menu">
        <ul className="navigation-menu">
          <li className={tabActive === "concept" ? "active" : ""}>
            <a onClick={() => setTabActive("concept")}>
              {" "}
              <img src={LessonMenuConcept} />{" "}
              <span className="navigation-menu--item"> {t("concept")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className={tabActive === "vocabulary" ? "active" : ""}>
            <a onClick={() => setTabActive("vocabulary")}>
              {" "}
              <img src={LessonMenuVocabulary} />
              <span className="navigation-menu--item"> {t("vocabulary")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className={tabActive === "example" ? "active" : ""}>
            <a onClick={() => setTabActive("example")}>
              {" "}
              <img src={LessonMenuExamples} />
              <span className="navigation-menu--item"> {t("example")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className={tabActive === "practice" ? "active" : ""}>
            <a onClick={() => setTabActive("practice")}>
              {" "}
              <img src={LessonMenuPractice} />
              <span className="navigation-menu--item"> {t("practice")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className={tabActive === "challenge" ? "active" : ""}>
            <a onClick={() => setTabActive("challenge")}>
              {" "}
              <img src={LessonMenuChallege} />
              <span className="navigation-menu--item"> {t("challenge")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className={tabActive === "additional" ? "active" : ""}>
            <a onClick={() => setTabActive("additional")}>
              {" "}
              <img src={LessonMenuMaterial} />
              <span className="navigation-menu--item"> {t("additionalMaterial")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className={tabActive === "download" ? "active" : ""}>
            <a onClick={() => setTabActive("download")}>
              {" "}
              <img src={LessonMenuDownload} />
              <span className="navigation-menu--item"> {t("downloadLesson")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className="highlight-item">
            <a onClick={goBack}>
              {" "}
              <img src={LessonMenuFinish} />
              <span className="navigation-menu--item"> {t("finish")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LessonMenu;
