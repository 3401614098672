import React from "react";
import "./style.css";
import currentSubject from "../../../../redux/actions/subject";
import { getLessonsBySubjectsAndGradesThuk } from "../../../../redux/actions/lessons";
import { useDispatch, useSelector } from "react-redux";
import { assignColor } from "../../../../utils/lesson";

const SubjectItem = ({ subject }) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.subject);

  const hadleSubjectSelected = () => {
    dispatch(currentSubject(subject));
    dispatch(getLessonsBySubjectsAndGradesThuk(subject.Level, subject.Subject));
  };

  const imageLoad = require(`../../../../assets/images/${assignIcon(
    subject.Subject
  )}`);

  function assignIcon(subject) {
    switch (subject) {
      case "en":
        return "LessonEnglish-icon.svg";
      case "sp":
        return "LessonSpanish-icon.svg";
      case "mat-en":
        return "LessonMath-icon.svg";
      case "sci-en":
        return "LessonScience-icon.svg";
      case "mat-sp":
        return "LessonMath-icon.svg";
      case "sci-sp":
        return "LessonScience-icon.svg";
      case "fi-sp":
        return "LessonScience-icon.svg";
      case "ca-sp":
        return "LessonScience-icon.svg";
      case "bi-sp":
        return "LessonScience-icon.svg";
      case "es-sp":
        return "LessonMath-icon.svg";
      case "fpc-sp":
        return "LessonMath-icon.svg";
      case "maa-sp":
        return "LessonMath-icon.svg";
      case "pa-sp":
        return "LessonMath-icon.svg";
      case "a1-sp":
        return "LessonMath-icon.svg";
      case "a2":
        return "LessonMath-icon.svg";
      case "geo-sp":
        return "LessonMath-icon.svg";
      case "tr-sp":
        return "LessonMath-icon.svg";
      case "pc-en":
        return "LessonMath-icon.svg";
      case "qu-sp":
        return "LessonScience-icon.svg";
      case "ci-sp":
        return "LessonScience-icon.svg";
      case "ct-sp":
        return "LessonScience-icon.svg";
      case "sci-so":
        return "LessonSocialStudies-icon.svg";
      case "edf-sp":
        return "LessonEducacionFisica-icon.svg";
      case "art-sp":
        return "LessonBellasArtes-icon.svg";
      case "sld-sp":
        return "LessonSalud-icon.svg";
      default:
        return "LessonDefault-icon.svg";
    }
  }

  return (
    <>
      <div className="wrapper-lessons">
        <a
          className={
            item.Level === subject.Level && item.Subject === subject.Subject
              ? "item-active"
              : ""
          }
          onClick={hadleSubjectSelected}
        >
          <div className="col-left">
            <img src={imageLoad} />{" "}
            <span className="title-lesson">{subject.SubjectName}</span>
          </div>
          <div
            className="col-right"
            style={{ backgroundColor: `${assignColor(subject.Subject)}` }}
          >
            <svg viewBox="0 0 57 105.6">
              <path
                className="arrow-right"
                style={{ fill: `${assignColor(subject.Subject)}` }}
                d="M56.6,105.6V0.1h-6.4L5.6,44.7C1,49.2,1,56.6,5.6,61.1L50,105.6H56.6z"
              />
            </svg>
            <span className="lesson-grade">{subject.Level}</span>
          </div>
        </a>
      </div>
    </>
  );
};

export default SubjectItem;
