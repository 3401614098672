import React, { useState } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { sendQuizHomework } from "../../../../api/homework";
import DropzoneQuiz from "../dropzone-quiz";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import prevPath from "../../../../redux/actions/path";
import ImageAttachment from "../attachment";

const Item = ({ item, index, onChange, submited }) => {
  const { t, i18n } = useTranslation(["homework"]);
  const handleChange = (e) => {
    const value = e.target.value;
    onChange(item.QuizQuestionModel.Id, value);
  };

  return (
    <>
      <form className="content-form">
        <div className="question">
          <div
            className="quiz-title"
            dangerouslySetInnerHTML={{
              __html: item.QuizQuestionModel.Desc,
            }}
          />
        </div>
        <p>
          <textarea
            placeholder={t("textOpenQuestion")}
            disabled={submited}
            name={`text${index}`}
            onChange={handleChange}
          ></textarea>
        </p>
      </form>
    </>
  );
};

const OpenQuestions = ({ quiz, code }) => {
  const { t, i18n } = useTranslation(["homework"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useSelector((state) => state.prevPath);
  const [values, setValues] = useState({});
  const [submited, setSubmited] = useState(null);
  const [lock, setLock] = useState(false);

  const handleFieldChange = (fieldId, value) => {
    setValues({ ...values, [fieldId]: value });
  };

  const handleSubmit = async () => {
    const questionList = Object.entries(values).map((value) => ({
      QuestionId: value[0],
      QuestionResponses: [{ ResponseExtra: value[1] }],
    }));
    setLock(true);
    try {
      const res = await sendQuizHomework(code, questionList);
      setSubmited(true);
      toastr.success("", t("textSubmited"));
    } catch {
    } finally {
      setLock(false);
    }
  };

  const goBack = () => {
    if (path && path.indexOf("/students/task") !== -1) {
      history.push("/students/task?q=homework");
      dispatch(prevPath(null));
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-iframe-form">
            <h4 className="title-page">{quiz.QuizModel.Title}</h4>

            <h1>{quiz.QuizModel.Title}</h1>
            <p className="instruction">
              <span
                dangerouslySetInnerHTML={{
                  __html: quiz.QuizModel.Instruction,
                }}
              />
            </p>
            {quiz.QuizExtraInfoModel && (
              <div
                dangerouslySetInnerHTML={{
                  __html: quiz.QuizExtraInfoModel.ExtraInfo,
                }}
              />
            )}

            {quiz.QuestionRequestModelList.map((item, i) => (
              <Item
                key={i}
                item={item}
                index={i}
                submited={submited}
                onChange={handleFieldChange}
              />
            ))}

            <div className="preview-docs-message">
              {quiz.QuizAttachmentModelList &&
                quiz.QuizAttachmentModelList.length !== 0 &&
                quiz.QuizAttachmentModelList.map((item, i) => (
                  <ImageAttachment key={i} item={item} />
                ))}
            </div>

            {!submited && (<div className="content-response-instruction">
              <div className="title-dropzone">{t("opcionalFile")}</div>
              <DropzoneQuiz code={code} />
              <h2>{t("validFile")}</h2>
              <p>
                png (Max: 98 KB) , jpeg (Max: 98 KB) , jpg (Max: 98 KB) , mp3
                (Max: 2 MB) , ogg (Max: 2 MB) , ogv (Max: 482 MB) , mp4 (Max: 95
                MB) , pdf (Max: 10 MB) , doc (Max: 98 KB) , docx (Max: 98 KB) ,
                csv (Max: 98 KB) , xlsx (Max: 98 KB) , xlsm (Max: 98 KB) , xls
                (Max: 98 KB) , txt (Max: 98 KB) , rtf (Max: 98 KB) , PNG (Max:
                195 KB) , TXT (Max: 98 KB)
              </p>
            </div>)}

            {!submited && (
              <div>
                <button
                  className="button-quiz"
                  type="button"
                  disabled={
                    Object.keys(values).length !==
                      quiz.QuestionRequestModelList.length || lock
                  }
                  onClick={handleSubmit}
                >
                  {t("submit")}
                </button>
                <button
                  className="button-quiz-back"
                  type="button"
                  onClick={goBack}
                >
                  {t("back")}
                </button>
              </div>
            )}
            {submited && (
              <button
                className="button-quiz-back"
                type="button"
                onClick={goBack}
              >
                {t("back")}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenQuestions;
