import React from "react";
import "./style.css";
import { Link, NavLink } from "react-router-dom";
import LessonMarkIcon from "../../../assets/images/lesson-mark.svg";
import HomeMenu from "../../../assets/images/HomeMenu-icon-Home.svg";
import HomeMenuTask from "../../../assets/images/HomeMenu-icon-Homework.svg";
import HomeMenuLesson from "../../../assets/images/HomeMenu-icon-Lessons.svg";
import HomeMenuMessage from "../../../assets/images/HomeMenu-icon-Message.svg";
import HomeMenuProgress from "../../../assets/images/HomeMenu-icon-Progress.svg";
import HomeMenuLibrary from "../../../assets/images/HomeMenu-icon-Library.svg";
import ForumMenuIcon from "../../../assets/images/Lesson-forum-icon.svg";
import ChatMenuIcon from "../../../assets/images/Lesson-chat.svg";
import KinderMenuIcon from "../../../assets/images/HomeMenu-icon-Kinder-GS.svg";
import MenuTeamsIcon from "../../../assets/images/Menu-icon-teams.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasRole, getUser } from "../../../utils/utils";
import { ROLE_STUDENT_STR, ROLE_TEACHER_STR, ROLE_DIRECTOR_STR } from "../../../config/environment";

const Menu = () => {
  const location = useLocation();
  const userCurrent = getUser();
  const { t, i18n } = useTranslation(["home"]);
  const { grade, user } = useSelector((state) => ({
    grade: state.grade,
    user: state.user,
  }));

  return (
    <>
      <div className="wrapper-menu scroll-bar">
        <ul className="navigation-menu">
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/home") ? "active" : ""
            }
          >
            <Link to="/students/home">
              <img src={HomeMenu} />{" "}
              <span className="navigation-menu--item"> {t("home")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/task") ? "active" : ""
            }
          >
            <Link to="/students/task">
              <img src={HomeMenuTask} />
              <span className="navigation-menu--item"> {t("homework")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_TEACHER_STR, ROLE_DIRECTOR_STR]) && <li
            className={
              location.pathname.includes("/students/lesson") ? "active" : ""
            }
          >
            <Link to="/students/lesson">
              <img src={HomeMenuLesson} />
              <span className="navigation-menu--item"> {t("lesson")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/message") ? "active" : ""
            }
          >
            <Link to="/students/message">
              <img src={HomeMenuMessage} />
              <span className="navigation-menu--item"> {t("message")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_DIRECTOR_STR]) && <li
            className={
              location.pathname.includes("/students/library") ? "active" : ""
            }
          >
            <Link to="/students/library">
              <img src={HomeMenuLibrary} />
              <span className="navigation-menu--item"> {t("library")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/progress") ? "active" : ""
            }
          >
            <Link to="/students/progress">
              <img src={HomeMenuProgress} />
              <span className="navigation-menu--item"> {t("progress")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/forum") ? "active" : ""
            }
          >
            <Link to="/students/forum">
              <img src={ForumMenuIcon} />
              <span className="navigation-menu--item"> {t("forum")} </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/teams-signin") ? "active" : ""
            }
          >
            <Link to="/students/teams-signin">
              <img src={MenuTeamsIcon} />
              <span className="navigation-menu--item"> Teams </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>}
          {/*asRole(userCurrent, [ROLE_STUDENT_STR]) && <li
            className={
              location.pathname.includes("/students/chat") ? "active" : ""
            }
          >
            <Link to="/students/chat">
              <img src={ChatMenuIcon} />
              <span className="navigation-menu--item"> Chat </span>
            </Link>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li> */}
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && grade === "k" && (
            <li
              className={
                location.pathname.includes("/students/kinder-interactive")
                  ? "active"
                  : ""
              }
            >
              <Link to="/students/kinder-interactive" className="nav-kinder">
                <img src={KinderMenuIcon} />
                <span className="navigation-menu--item"> Kinder </span>
              </Link>
              <span className="lesson-mark">
                <img src={LessonMarkIcon} />
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Menu;
