export function assignIcon(subject) {
  switch (subject) {
    case "en":
      return "LessonEnglish-icon.svg";
    case "sp":
      return "LessonSpanish-icon.svg";
    case "mat-en":
      return "LessonMath-icon.svg";
    case "sci-en":
      return "LessonScience-icon.svg";
    case "mat-sp":
      return "LessonMath-icon.svg";
    case "sci-sp":
      return "LessonScience-icon.svg";
    case "sci-so":
      return "LessonSocialStudies-icon.svg";
    case "edf-sp":
      return "LessonEducacionFisica-icon.svg";
    case "art-sp":
      return "LessonBellasArtes-icon.svg";
    case "sld-sp":
      return "LessonSalud-icon.svg";
    default:
      return "LessonDefault-icon.svg";
  }
}

export const getNameSubject = (subjectCode, subjects) => {
  let subject = subjects.find((sub) => sub.Subject === subjectCode);
  if (subject) {
    return subject.SubjectName;
  }
  return "";
};

export const removeDuplicates = (arr) => {
  
  const arrMap = arr.map(elemento => {
    return [JSON.stringify(elemento), elemento]
  });

  return [...new Map(arrMap).values()];
}
