import React, { useEffect, useState, createContext, useRef } from "react";
import { Switch, Redirect } from "react-router-dom";
import RoleRoute from "../route/role-route";
import KinderRoute from "../route/kinder-route";
import PrivateRoute from "../route/private-route";
import Header from "./header";
import Home from "./home";
import Footer from "./footer";
import Lesson from "./lesson";
import Task from "./task";
import Library from "./library/library";
import BookDetail from "./library/book-detail";
import Message from "./message";
import ProgressTabs from "./progress/tabs";
import LessonDetail from "./lesson/lesson-detail";
import Quiz from "./lesson/lesson-detail/quiz";
import Homework from "./homework";
import Forum from "./forum";
import Chat from "./chat";
import NotAuthorized from "../common/not-authorized/not-authorized";
import ForumDetail from "./forum/forum-detail";
import SignIn from "./teams/signin";
import Calendar from "./teams/calendar";
import KinderInteractive from "./kinder-interactive";
import GSInteractive from "./gs-interactive";
import io from "socket.io-client";
import { SOCKET_URL } from "../../config/environment";
import { useSelector } from "react-redux";
import { hasRole, getUser } from "../../utils/utils";
import { ROLE_STUDENT_STR, ROLE_TEACHER_STR, ROLE_DIRECTOR_STR } from "../../config/environment";

export const ChatContext = createContext({});

let socket;

const Students = () => {
  const { user } = useSelector((state) => ({
    user: state.user,
  }));
  const userCurrent = getUser();
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [chatSelected, setChatSelected] = useState(null);
  const usersRef = useRef(users);
  const chatsRef = useRef(chats);
  const chatSelectedRef = useRef(chatSelected);
  const username = user.Username;

  useEffect(() => {
    usersRef.current = users;
    chatsRef.current = chats;
    chatSelectedRef.current = chatSelected;
  });

  useEffect(() => {
    initSocket();
    return () => {
      closeSocket();
    };
  }, []);

  const initSocket = () => {
    socket = io(SOCKET_URL);

    socket.on("connect", () => {
      socket.emit("CONNECT_USER", { username }, (error) => {
        if (error) {
          console.log(error);
        }
      });
    });

    socket.on("USERS_ONLINE", ({ users }) => {
      const chats = chatsRef.current;
      setChats(chats);
      setUsers(users);
    });

    socket.on("LOAD_CHATS", ({ chats }) => {
      setChats(chats);
    });

    socket.on("SEND_CHAT", ({ chat, exists }) => {
      const users = usersRef.current;
      const usersNickname = users.map((user) => user.username);
      chat.name = createNickReceived(chat.users, username);
      chat.online = usersNickname.includes(chat.name);

      if (!exists) {
        setChats((state) => [...state, chat]);
        setChatSelected(chat);
      } else {
        const chats = chatsRef.current;
        const chatExists = chats.find((item) => item._id === chat._id);
        if (!chatExists) {
          setChats((state) => [...state, chat]);
        }
        setChatSelected(chat);
      }
    });

    socket.on("MESSAGE_RECIEVED", ({ chatUpdated }) => {
      const chats = chatsRef.current;

      const chatExists = chats.find((item) => item._id === chatUpdated._id);
      if (!chatExists) {
        chatsRef.current = [...chats, chatUpdated];
      }

      let newChats = chatsRef.current.map((item) => {
        if (item._id === chatUpdated._id) {
          return chatUpdated;
        }
        return item;
      });
      setChats(newChats);

      const chatSelected = chatSelectedRef.current;

      setChatSelected(
        chatSelected && chatSelected._id === chatUpdated._id
          ? chatUpdated
          : chatSelected
      );
    });

    socket.on("CHAT_MARKED_READ", ({ chatUpdated }) => {
      const chats = chatsRef.current;

      const chatExists = chats.find((item) => item._id === chatUpdated._id);
      if (!chatExists) {
        chatsRef.current = [...chats, chatUpdated];
      }

      let newChats = chatsRef.current.map((item) => {
        if (item._id === chatUpdated._id) {
          return chatUpdated;
        }
        return item;
      });
      setChats(newChats);
    });
  };

  const handleSendMessage = (message) => {
    socket.emit("SEND_MESSAGE", {
      sender: username,
      chatId: chatSelected._id,
      message,
    });
  };

  const markChatRead = (chat) => {
    socket.emit("MARK_CHAT_READ", {
      sender: username,
      chatId: chat._id,
    });
  };

  const closeSocket = () => {
    socket.disconnect();
  };

  const handleCreateChat = (reciever) => {
    socket.emit("START_CHAT", {
      reciever,
      sender: username,
    });
  };

  const createNickReceived = (users, excludedUser) => {
    return users.filter((u) => u !== excludedUser).join("");
  };

  return (
    <>
      <ChatContext.Provider
        value={{
          users,
          chats,
          chatSelected,
          setChatSelected,
          handleSendMessage,
          handleCreateChat,
          markChatRead,
          createNickReceived
        }}
      >
        <Header />
        <Switch>
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/home" component={Home} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_DIRECTOR_STR])} exact path="/students/lesson" component={Lesson} />
          <RoleRoute
            authorized={hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_TEACHER_STR, ROLE_DIRECTOR_STR])}
            exact
            path="/students/lesson/:lessonId"
            component={LessonDetail}
          />
          <RoleRoute
            authorized={hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_TEACHER_STR, ROLE_DIRECTOR_STR])}
            exact
            path="/students/lesson/quiz/:quizId"
            component={Quiz}
          />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/task" component={Task} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_DIRECTOR_STR])} exact path="/students/library" component={Library} />
          <RoleRoute
            authorized={hasRole(userCurrent, [ROLE_STUDENT_STR, ROLE_DIRECTOR_STR])}
            exact
            path="/students/library/:bookId"
            component={BookDetail}
          />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/message" component={Message} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/progress" component={ProgressTabs} />
          <RoleRoute
            authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])}
            exact
            path="/students/homework/:teacherPlanQuizId"
            component={Homework}
          />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/forum" component={Forum} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/chat" component={Chat} />
          <RoleRoute
            authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])}
            exact
            path="/students/forum/:forumId"
            component={ForumDetail}
          />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/teams-signin" component={SignIn} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/teams-calendar" component={Calendar} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/kinder-interactive" component={KinderInteractive} />
          <RoleRoute authorized={hasRole(userCurrent, [ROLE_STUDENT_STR])} exact path="/students/gs-interactive" component={GSInteractive} />   
          <PrivateRoute path="/students" component={NotAuthorized} />   
          <Redirect from="/students" to="/students" />
        </Switch>
        <Footer />
      </ChatContext.Provider>
    </>
  );
};

export default Students;
